<template>
  <div class="mt-1">
    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          </b-col>
          <b-col cols="12" md="7">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="addSite()">
                <span class="text-nowrap">Add Department</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-table
          :outlined="true"
          :fields="fields"
          responsive="sm"
          class="table table-sm"
          :items="filteredSites"
        >
          <template #cell(department)="data">
            <div class="p-25">
              {{ data.value.title }}
            </div>
          </template>

          <template #cell(title)="data">
            <div class="p-25">
              {{ data.value }}
            </div>
          </template>

          <template #cell(actions)="data">
            <div>
              <b-button
                variant="primary"
                class="mr-25 p-50"
                @click="editSite(data.item.id)"
              >
                <i class="fa-solid fa-pen-to-square mr-25"></i>
                <span> Edit</span>
              </b-button>

              <b-button
                variant="primary"
                @click="deleteSite(data.item.id)"
                class="p-50"
              >
                <i class="fa-solid fa-trash mr-25"></i>
                <span> Delete</span>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
} from "bootstrap-vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../settingsStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const TASK_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(TASK_APP_STORE_MODULE_NAME))
      store.registerModule(TASK_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP_STORE_MODULE_NAME))
        store.unregisterModule(TASK_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      sites: [],
      filter: null,
      loading: true,
      fields: [
        {
          key: "department",
          label: "Site",
          sortable: true,
          class: "text-left",
          thStyle: "width:20%",
        },
        {
          key: "title",
          label: "Department",
          sortable: true,
          class: "text-left",
          thStyle: "width:20%",
        },
        {
          key: "start_am_time",
          label: "Start AM Time",
          sortable: true,
          class: "text-center",
          thStyle: "width:10%",
        },
        {
          key: "end_am_time",
          label: "End AM Time",
          sortable: true,
          class: "text-center",
          thStyle: "width:10%",
        },
        {
          key: "start_pm_time",
          label: "Start PM Time",
          sortable: true,
          class: "text-center",
          thStyle: "width:10%",
        },
        {
          key: "end_pm_time",
          label: "End PM Time",
          sortable: true,
          class: "text-center",
          thStyle: "width:10%",
        },
        {
          key: "actions",
          sortable: false,
          class: "text-center",
          thStyle: "width:20%",
        },
      ],
      tempName: null,
      showAllAdditionals: false,
    };
  },

  watch: {},

  methods: {
    getSites() {
      this.loading = true;
      store
        .dispatch("settings/getAllSites")
        .then((res) => {
          this.sites = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: "settings" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    editSite(id) {
      this.$router.push({ name: "settings-site-edit", params: { id: id } });
    },

    deleteSite(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this department?", {
          size: "sm",
          title: "Confirm Deletion",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const site = this.sites.find((met) => met.id === id);

            if (site) {
              store
                .dispatch("settings/deleteSite", site)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Successful",
                      text: "✔️ Department Delete Successful",
                      icon: "ThumbsUpIcon",
                      variant: "success",
                    },
                  });

                  this.getSites();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "An error occurred",
                      text: "Please try again later or contact support.",
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                });
            }
          }
        });
    },

    addSite() {
      this.$router.push({ name: "settings-site-add" });
    },
  },

  created() {
    this.getSites();
  },

  computed: {
    filteredSites() {
      if (!this.filter) {
        return this.sites;
      } else {
        this.sites = this.sites.filter((met) => met.id !== undefined);

        this.sites.forEach((met) => {
          met.editable = false;
        });

        const searchTerm = this.filter.toLowerCase();
        return this.sites.filter((met) =>
          met.code.toLowerCase().includes(searchTerm)
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
